<template>
  <table class="w-full table-fixed" :class="tableClass">
    <thead :class="headClass">
      <tr>
        <template v-for="(column, colIndex) in columns" :key="`column-${colIndex}`">
          <slot v-if="hasSlot(`head-${colIndex}`)" :name="`head-${colIndex}`"></slot>
          <st-th
            v-else
            v-model:checkBoxValue="checkBoxValue"
            :tooltipUse="column.titleTooltip !== undefined && column.titleTooltip !== ''"
            :isShowIcon="column.isShowIcon"
            :style="{ width: column.width }"
            :sortingUse="!!column.sortKey"
            :thTitle="$t(column.title ?? '')"
            :thTitleTooltip="$t(column.titleTooltip ?? '')"
            :checkboxUse="column.checkable"
            :sortValue="sortList[colIndex]"
            :class="{
              [`${column.headClass}`]: !!column.headClass,
              'sticky left-0 top-0 z-[30]': headerSticky && colIndex === 0,
              'sticky top-0 z-[20]': headerSticky && colIndex !== 0
            }"
            :tooltipText="column.titleTooltip"
            :thTitleClass="column.thTitleClass"
            :thClass="column.thClass"
            :tooltipPlacement="column.tooltipPlacement as any"
            :offset="column.offset"
            @onSort="
              (sortValue) => {
                for (let i = 0; i < sortList.length; i++) {
                  if (i !== colIndex) {
                    sortList[i] = SORT_VALUE.NONE;
                  }
                }
                sortList[colIndex] = sortValue;
                column.onSort && column.onSort({ sortKey: column.sortKey ?? '', sortValue, data });
              }
            "
            @onCheckboxUpdate="($event) => $emit('onCheckboxUpdate', $event)"
          />
        </template>
      </tr>
    </thead>
    <tbody v-if="(!data || data.length === 0) && hasSlot('empty')">
      <slot :name="'empty'"></slot>
    </tbody>
    <tbody v-else :class="bodyClass">
      <template v-for="(item, rowIndex) in items" :key="`row-${rowIndex}`">
        <slot
          v-if="hasSlot(`row-${rowIndex}`)"
          :name="`row-${rowIndex}`"
          :row="item"
          :index="rowIndex"
        ></slot>
        <slot v-else-if="hasSlot(`row`)" :name="`row`" :row="item" :index="rowIndex"></slot>
        <st-tr
          v-else
          :hasLink="instance?.vnode?.props?.onOnRowClick && showCursorPointer"
          :hoverable="showHoverEffect"
          @onRowClick="$emit('onRowClick', item)"
        >
          <template v-for="(column, colIndex) in columns" :key="`col-${rowIndex}-${colIndex}`">
            <slot
              v-if="hasSlot(`cell-${rowIndex}-${colIndex}`)"
              :name="`cell-${rowIndex}-${colIndex}`"
              :cell="column.dataIndex ? item[column.dataIndex as keyof T] : ''"
              :row="item"
            ></slot>
            <slot
              v-else-if="hasSlot(`col-${colIndex}`)"
              :name="`col-${colIndex}`"
              :row="item"
              :rowIndex="rowIndex"
            ></slot>
            <st-td
              v-else
              :aLeft="column.align === 'left'"
              :aRight="column.align === 'right'"
              :aCenter="column.align === 'center'"
              :textClass="column.fontWeight === 'bold' ? 'text-md font-medium' : 'text-md'"
              :class="renderCellClass(column)"
              :textMedium="column.fontWeight === 'normal'"
              :tdValue="
                column.render
                  ? column.render(item)
                  : column.dataIndex
                    ? item[column.dataIndex as keyof T]?.toString()
                    : ''
              "
              @click="column.cellClick && column.cellClick(item)"
            />
          </template>
        </st-tr>
      </template>
    </tbody>
  </table>
  <s-pagination
    v-if="paging && paging?.show && pageCount > 0 && data.length > 0"
    v-model="currentPage"
    :pageCount="pageCount"
    displayFirstAndLastButton
    class="!py-32"
    @change="onPageChange"
  />
</template>

<script setup lang="ts" generic="T = any">
import { computed, defineModel, getCurrentInstance, ref, useSlots } from 'vue';

import StTd from '@/components/common/table/st-td.vue';
import StTh from '@/components/common/table/st-th.vue';
import StTr from '@/components/common/table/st-tr.vue';
import { SORT_VALUE } from '@/constants/common.const';
import { DEFAULT_PAGE_SIZE } from '@/constants/table.const';
import type { TableProps } from '@/types/table.type';

const slots = useSlots();
const props = withDefaults(defineProps<TableProps<T>>(), { showCursorPointer: true });

const emits = defineEmits<{
  onRowClick: [v: T];
  pageChange: [v: number, items?: any[]];
  onCheckboxUpdate: [v: boolean];
}>();

const instance = getCurrentInstance();

const sortList = props.columns.map(() => SORT_VALUE.NONE);

const currentPage = ref<number>(1);
const checkBoxValue = defineModel<boolean>('checkBoxValue', { default: false });
const renderPage = ref<number>(1);

const items = computed(() => {
  const { paging, data } = props;
  if (!data) {
    return [];
  }

  if (!paging || !paging.show) {
    return props.data;
  }

  let { pageSize } = paging;
  if (!pageSize) {
    pageSize = DEFAULT_PAGE_SIZE;
  }

  if (data.length <= pageSize) {
    return data;
  }

  return data.slice((currentPage.value - 1) * pageSize, currentPage.value * pageSize);
});

const renderCellClass = (column: TableProps<T>['columns'][0]) => {
  let result = '';
  if (column.cellClick) {
    result += 'cursor-pointer';
  }
  if (column.cellClass) {
    result += result.length ? ` ${column.cellClass}` : column.cellClass;
  }
  return result;
};

const pageCount = computed(() => {
  const { paging } = props;
  return Math.ceil((paging?.total ?? 0) / (paging?.pageSize ?? DEFAULT_PAGE_SIZE));
});

const onPageChange = (page: number) => {
  if (page === renderPage.value) {
    return;
  }
  renderPage.value = page;
  emits('pageChange', page, items.value);
};

const hasSlot = (name: string) => !!slots[name];
</script>
